import Cookies from 'js-cookie';
import { signIn } from 'next-auth/react';

import logger from '@common/log';
import { CrmAccountWithRole, Impersonation } from '@types-be/next-auth';

import { LS_KEY_ACCOUNTS, LS_KEY_IMPERSONATION, LS_KEY_SELECTED_ACCOUNT } from './constants';
import {useAccountCookie} from './useAccountCookie';


export function useSelfServiceAccount() {
  const [accounts, setAccounts] = useAccountCookie<Array<CrmAccountWithRole>>(LS_KEY_ACCOUNTS);
  const [selectedAccount, setSelectedAccount] = useAccountCookie<CrmAccountWithRole>(LS_KEY_SELECTED_ACCOUNT);
  const [impersonation, setImpersonation] = useAccountCookie<Impersonation>(LS_KEY_IMPERSONATION);

  const isCurrentAccountReader = selectedAccount?.role === 'reader';
  const isCurrentAccountEditor = selectedAccount?.role === 'editor';
  const isCurrentAccountOwner = selectedAccount?.role === 'owner';

  const refreshAccounts = (callbackUrl?: string) => {
    signIn('okta', { callbackUrl });
    if (accounts && accounts.length > 0) {
      setSelectedAccount(accounts[0]);
    } else {
      Cookies.remove(LS_KEY_SELECTED_ACCOUNT);
    }
  };

  const updateAccountAndRefreshJWT = (account: CrmAccountWithRole, callbackUrl?: string) => {
    setSelectedAccount(account);
    signIn('okta', { callbackUrl });
  };

  /**
   * Switching account to different account number
   * @param crmAccountNumber number of account to switch to
   * @param newAccounts only necessary when you use setAccounts right before switching
   */
  const switchAccount = (crmAccountNumber: string, newAccounts?: Array<CrmAccountWithRole>) => {
    const accountToSelect =
      accounts.length > 0
        ? accounts?.find(account => account.crmAccountNumber === crmAccountNumber)
        : newAccounts?.find(account => account.crmAccountNumber === crmAccountNumber);
    if (accountToSelect) setSelectedAccount(accountToSelect);
    else logger.error('dD3FXH', `Selected account doesn't exist`);
  };

  const impersonate = (impersonation: Impersonation) => {
    setImpersonation(impersonation);
  };
  const isImpersonating = () => impersonation && impersonation.type !== 'None';

  const setEmptyAccount = (name: string) => {
    setSelectedAccount({ crmAccountNumber: '', alias: name, role: 'owner' });
  };

  const isProspect = accounts && accounts.length === 0;

  return {
    selectedAccount,
    accounts,
    refreshAccounts,
    setAccounts,
    isCurrentAccountReader,
    isCurrentAccountOwner,
    isCurrentAccountEditor,
    impersonate,
    impersonation,
    isImpersonating,
    updateAccountAndRefreshJWT,
    switchAccount,
    setEmptyAccount,
    isProspect,
  };
}
