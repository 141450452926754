// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgToonServiceIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M22 7v10c0 1.66-1.34 3-3 3h-3v-3h3V7H5v10h3v3H5c-1.66 0-3-1.34-3-3V7c0-1.66 1.34-3 3-3h14c1.66 0 3 1.34 3 3Zm-7.05 2.68A1 1 0 0 0 14 9h-.5v3c0 .55-.34 1-.75 1h-1.5c-.41 0-.75-.45-.75-1V9H10c-.43 0-.81.28-.95.68l-1 3c-.12.36-.03.76.24 1.02L10 15.41V20h4v-4.59l1.71-1.71a.99.99 0 0 0 .24-1.02l-1-3Z" />
  </Icon>
);
export default SvgToonServiceIcon;
SvgToonServiceIcon.displayName = 'SvgToonServiceIcon';
