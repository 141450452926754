import React from 'react';

import { capitalizeFirstLetter } from '@common/string';
import { WeekdayProps } from 'react-day-picker';

import { Text } from '../../Text/Text';

export const Weekday = (props: WeekdayProps) => {
  const { children, ...columnProps } = props;

  return (
    <th {...columnProps}>
      <Text size="BodyS" color="textLowEmphasis" weight="bold">
        {capitalizeFirstLetter(`${children}`)}
      </Text>
    </th>
  );
};
