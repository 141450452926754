import React, { ReactNode } from 'react';

import * as icons from '../icons/eneco';

type IconType = React.FC<React.SVGProps<SVGSVGElement>> & {
  displayName: `Svg${string}`;
};

/**
 * Determine whether the children contain a specific component. This function
 *  utilizes the comparison of the `displayName` property of the component to determine whether the children
 *  contain the specified component.
 *
 * @param children List of React children
 * @param component Component to search for
 * @returns boolean indicating whether the children contain the specified component
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function hasComponent(children: ReactNode, component: React.ComponentType<any>): boolean {
  let hasComponent = false;

  React.Children.forEach(children, child => {
    if (!React.isValidElement(child) || hasComponent) return;
    hasComponent =
      React.isValidElement(child) &&
      (child.type as React.ComponentType & { displayName: string }).displayName === component.displayName;
  });

  return hasComponent;
}

/**
 * Determine whether the children (or any of their subchildren) contain a Sparky icon
 *
 * @param children List of React children
 * @returns boolean indicating whether the children (or any of their subchildren) contain a Sparky icon
 */
export function hasIcon(children: ReactNode): boolean {
  let hasIcon = false;
  const iconNames = Object.keys(icons);

  const checkChildren = (child: ReactNode): void => {
    if (!hasIcon && React.isValidElement(child)) {
      if (
        (child.type as IconType).displayName &&
        iconNames.includes((child.type as IconType).displayName?.replace(/^Svg/, ''))
      ) {
        hasIcon = true;
      } else {
        React.Children.forEach(child.props.children, checkChildren);
      }
    }
  };

  React.Children.forEach(children, checkChildren);

  return hasIcon;
}
