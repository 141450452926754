import React, { FC } from 'react';

import { startOfMonth } from '@common/date';
import { isValid } from 'date-fns';
import { setYear } from 'date-fns/setYear';
import { useDayPicker } from 'react-day-picker';

import { Box } from '../../Box/Box';
import { InputSelect } from '../../InputSelect/InputSelect';

export const YearsDropdown: FC<{ date: Date }> = ({ date }) => {
  const {
    dayPickerProps: { endMonth, mode, numberOfMonths, startMonth },
    goToMonth,
    labels: { labelYearDropdown },
  } = useDayPicker();

  if (mode === 'range' || numberOfMonths !== 1) return null;
  if (!endMonth || !isValid(endMonth)) return null;
  if (!startMonth || !isValid(startMonth)) return null;

  const fromYear = startMonth.getFullYear();
  const toYear = endMonth.getFullYear();

  if (fromYear === toYear) return null;

  const years = Array.from({ length: toYear - fromYear + 1 }, (_, i) => {
    const year = `${fromYear + i}`;
    return { label: year, value: year };
  });

  const handleChange: React.ChangeEventHandler<HTMLSelectElement> = e => {
    const newMonth = setYear(startOfMonth(date), Number(e.target.value));
    goToMonth(newMonth);
  };

  return (
    <Box paddingLeft="10">
      <InputSelect
        aria-label={labelYearDropdown()}
        defaultValue={`${date.getFullYear()}`}
        filteredElement="Calendar"
        label="Years"
        name="years"
        onChange={handleChange}
        options={years}
        type="filter"
        visuallyHideLabel
      />
    </Box>
  );
};
