import { FC } from 'react';

import { Notification } from '@components-next/Notification/Notification';
import { StackContainer } from '@custom-components/selfservice';
import { SelfservicePageTitle } from '@custom-components/SelfservicePageTitle';
import { Placeholder, useLayoutData, usePlaceholder } from '@sitecore/common';
import { Box, PageGrid, Stack, Hidden, SkipToMain } from '@sparky';

export const DefaultLayout: FC = () => {
  const { route } = useLayoutData();
  const pageTitle =
    typeof route?.fields?.pageTitle?.value === 'string' ? route.fields.pageTitle.value : 'Page title missing';
  const pageSubTitle =
    typeof route.fields?.pageSubTitle?.value === 'string' ? route.fields.pageSubTitle.value : undefined;

  const hasInitialRightColumn = !!route.placeholders['jss-right']?.length;
  const hasInitialMainBottom = !!route.placeholders['jss-main-bottom']?.length;
  const { hasScopedComponents } = usePlaceholder();

  const hasRightColumn = hasScopedComponents('jss-right') || hasInitialRightColumn;
  const hasMainBottom = hasScopedComponents('jss-main-bottom') || hasInitialMainBottom;

  return (
    <>
      <Placeholder name="jss-meta" />

      <StackContainer>
        <SkipToMain.SkipLink />
        <header>
          <Placeholder name="jss-header" />
          <Placeholder name="jss-navigationbar" />
          <Placeholder name="jss-notifications" />
        </header>

        <Stack.Item grow>
          <PageGrid
            gridTemplateAreas={{
              // prettier-ignore
              initial: `
                                    "title  title  title  title  title  title  title  title  title "
                                    "main   main   main   main   main   main   main   main   main  "
                ${hasRightColumn ? '"right  right  right  right  right  right  right  right  right"' : ''}
                ${hasMainBottom ?  '"bottom bottom bottom bottom bottom bottom bottom bottom bottom"' : ''}
              `,
              // prettier-ignore
              md: `
                                    ".     .      .    title  title  title  title  title  title  title  title  title "
                                    "left  left  left  main   main   main   main   main   main   main   main   main  "
                ${hasRightColumn ? '"left  left  left  right  right  right  right  right  right  right  right  right "' : ''}
                ${hasMainBottom ?  '"left  left  left  bottom bottom bottom bottom bottom bottom bottom bottom bottom"' : ''}
              `,
              // prettier-ignore
              lg: `
                                    ".     .      .    title  title  title  title  title  title  .     .     ."
                                    "left  left  left  main   main   main   main   main   main   right right right"
                ${hasMainBottom ?  '"left  left  left  bottom bottom bottom bottom bottom bottom right right right"' : ''}
              `,
            }}
            gridTemplateRows="auto auto 1fr">
            {/* Left column */}
            <Hidden below="md">
              <PageGrid.Item gridArea="left">
                <Placeholder name="jss-left" />
              </PageGrid.Item>
            </Hidden>

            {/* Title cell */}
            {pageTitle?.length > 0 && (
              <PageGrid.Item gridArea="title">
                <SelfservicePageTitle title={pageTitle} subTitle={pageSubTitle} />
              </PageGrid.Item>
            )}

            {/* Center/main column */}
            <PageGrid.Item gridArea="main">
              <Notification />
              <SkipToMain.Main>
                <Stack gap={{ initial: '2', md: '3', lg: '4', xl: '6' }}>
                  <Placeholder name="jss-main" />
                </Stack>
              </SkipToMain.Main>
            </PageGrid.Item>

            {/* Main bottom column */}
            {hasMainBottom && (
              <PageGrid.Item gridArea="bottom">
                <Stack gap={{ initial: '2', md: '3', lg: '4', xl: '6' }}>
                  <Placeholder name="jss-main-bottom" />
                </Stack>
              </PageGrid.Item>
            )}

            {/* Right column */}
            {hasRightColumn && (
              <PageGrid.Item gridArea="right">
                <Stack gap={{ initial: '2', md: '3', lg: '4', xl: '6' }}>
                  <Placeholder name="jss-right" />
                </Stack>
              </PageGrid.Item>
            )}
          </PageGrid>
        </Stack.Item>

        <footer>
          <Box paddingTop="3">
            <Placeholder name="jss-footer" />
            {/* Below placeholder is added to accomodate the Usabilla Feedback component on every page. */}
            <Placeholder name="jss-footer-bottom" />
          </Box>
        </footer>
      </StackContainer>
    </>
  );
};
