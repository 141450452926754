import { useEffect, useRef } from 'react';

import { DayButtonProps } from 'react-day-picker';

import { DayButton as StyledDayButton } from '../Datepicker.styles';

export const DayButton = (props: DayButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const {
    day,
    modifiers: { focused },
    ...buttonProps
  } = props;

  useEffect(() => {
    if (focused) buttonRef.current?.focus();
  }, [focused]);

  return <StyledDayButton name="day" ref={buttonRef} {...buttonProps} />;
};
