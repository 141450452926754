// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgDongleIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M11 9c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2Zm11 5c0 1-.6 1.9-1.5 2.3-.2 3.2-2.9 5.7-6.2 5.7-3.3 0-6.2-2.7-6.2-6-3.4-.6-6-3.5-6-7s3.1-7 7-7 7 3.1 7 7-2.6 6.4-6 6.9c0 2.3 1.9 4.1 4.2 4.1 2.3 0 4.1-1.7 4.2-3.7-.8-.3-1.3-1-1.4-1.9V14c0-1.4 1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5H22Zm-8.1-5.8c-.3-2.1-2-3.8-4.1-4.1-3.4-.5-6.2 2.4-5.7 5.7.3 2.1 2 3.8 4.1 4.1 3.4.5 6.2-2.4 5.7-5.7Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default SvgDongleIcon;
SvgDongleIcon.displayName = 'SvgDongleIcon';
