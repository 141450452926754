import { FC } from 'react';

import { capitalizeFirstLetter } from '@common/string';
import { MonthCaptionProps, useDayPicker } from 'react-day-picker';

import { YearsDropdown } from './YearsDropdown';
import { ChevronLeftIcon, ChevronRightIcon } from '../../../icons';
import { Icon } from '../../../icons/IconWrapper';
import { useI18nTranslations } from '../../../providers/i18n';
import { styled } from '../../../stitches.config';
import { Box } from '../../Box/Box';
import { Divider } from '../../Divider/Divider';
import { Grid } from '../../Grid/Grid';
import { IconButton } from '../../IconButton/IconButton';
import { Stack } from '../../Stack/Stack';
import { Text } from '../../Text/Text';

const DisabledButton = styled('div', {
  width: '$targetMinWidth',
  height: '$targetMinHeight',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  [`& ${Icon}`]: { fill: '$iconSecondary' },
});

const NextChevron: FC<{ date: Date }> = ({ date }) => {
  const { previousMonth: i18nNextMonth } = useI18nTranslations();
  const { goToMonth, months, nextMonth } = useDayPicker();

  // In cases where two months are displayed at a time (e.g. range mode), the right chevron should only be shown for
  //   the right most month
  if (months.length > 1 && months[months.length - 1].date.getMonth() !== date.getMonth()) return <DisabledButton />;

  return nextMonth ? (
    <IconButton label={i18nNextMonth} onClick={() => goToMonth(nextMonth)}>
      <ChevronRightIcon />
    </IconButton>
  ) : (
    <DisabledButton>
      <ChevronRightIcon />
    </DisabledButton>
  );
};

const PreviousChevron: FC<{ date: Date }> = ({ date }) => {
  const { previousMonth: i18nPreviousMonth } = useI18nTranslations();
  const { goToMonth, months, previousMonth } = useDayPicker();

  // In cases where two months are displayed at a time (e.g. range mode), the left chevron should only be shown for
  //   the left most month
  if (months.length > 1 && months[0].date.getMonth() !== date.getMonth()) return <DisabledButton />;

  return previousMonth ? (
    <IconButton label={i18nPreviousMonth} onClick={() => goToMonth(previousMonth)}>
      <ChevronLeftIcon />
    </IconButton>
  ) : (
    <DisabledButton>
      <ChevronLeftIcon />
    </DisabledButton>
  );
};

export const MonthCaption = (props: MonthCaptionProps) => {
  const {
    dayPickerProps: { locale },
    months,
  } = useDayPicker();

  const {
    calendarMonth: { date },
  } = props;

  return (
    <>
      <Box padding="6">
        <Grid gridTemplateColumns="1fr max-content">
          <Grid.Item>
            <Stack gap="1" direction="row" alignY="center" alignX="justify">
              <PreviousChevron date={date} />
              <Text size="BodyL" weight="bold">
                {capitalizeFirstLetter(date.toLocaleString(locale?.code, { month: 'long' }))}
                {months.length > 1 ? ` ${date.getFullYear()}` : ''}
              </Text>
              <NextChevron date={date} />
            </Stack>
          </Grid.Item>
          <Grid.Item alignSelf="end">
            <YearsDropdown date={date} />
          </Grid.Item>
        </Grid>
      </Box>
      <Divider />
    </>
  );
};
