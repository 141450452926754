import React, { forwardRef, KeyboardEvent, useEffect, useRef } from 'react';

import { throttle } from '@common/fn';
import {
  Combobox as AriaCombobox,
  ComboboxItem,
  ComboboxPopover,
  useComboboxState,
  ComboboxCancel,
} from 'ariakit/combobox';
import parse, { DOMNode, domToReact } from 'html-react-parser';

import { useInputIds } from '../../hooks/useInputIds';
import { useValidationErrorEvent } from '../../hooks/useValidationErrorEvent';
import { CloseIcon } from '../../icons/eneco';
import { useI18nTranslations } from '../../providers/i18n';
import { styled } from '../../stitches.config';
import { InputBaseProps } from '../Input/Input';
import { Error } from '../Input/InputError';
import { Hint } from '../Input/InputHint';
import { Label } from '../Input/InputLabel';
import { InputButtonStyles } from '../InputPassword/InputPassword';
import { Stack } from '../Stack/Stack';
import { Text } from '../Text/Text';

const StyledBackground = styled('div', {
  /**
   * Since the combobox is styled as an input, but not actually an input, we use a separate element for the background.
   * We can style it based on the input state. This is a bit of a hack, but it works great.
   * */
  position: 'absolute',
  bottom: 'var(--combobox-background-bottom, auto)',
  top: 'var(--combobox-background-top, 0)',
  left: 0,
  right: 0,
  zIndex: 0,
  height: 'calc(100% + var(--combobox-popover-height))',
  backgroundColor: '$backgroundPrimary',
  border: '$borderWidths$s solid $formBorderDefault',
  borderRadius: '$s',
});

const StyledComboboxItem = styled(ComboboxItem, {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  scrollMargin: '$2',
  padding: '$2 $4',
  typography: '$bodyL',

  '&:last-child': {
    borderRadius: '0 0 $s $s',
  },

  '&:hover': {
    backgroundColor: '$backgroundSecondary',
  },

  '&[data-active-item]': {
    backgroundColor: '$backgroundTertiary',
  },
});

const StyledInputWrapper = styled('div', {
  position: 'relative',

  variants: {
    isOpen: {
      true: {
        // The divider between the input and the popover
        '&::before': {
          content: '',
          position: 'absolute',
          width: 'calc(100% - $space$6)',
          bottom: 'var(--combobox-divider-bottom, auto)',
          top: 'var(--combobox-divider-top, 0)',
          left: '$3',
          height: '$borderWidths$s',
          backgroundColor: '$borderDividerLowEmphasis',
          zIndex: 5,
        },
      },
    },
  },
});

//Unstyled input, all styling is applied to StyledBackground
const StyledInput = styled(AriaCombobox, {
  all: 'unset',
  boxSizing: 'border-box',
  position: 'relative',
  zIndex: 1,
  minHeight: '$inputMinHeight',
  paddingY: '$3',
  paddingX: '$4',
  fontSize: '$BodyL',
  color: '$textPrimary',
  width: '100%',

  '&:focus': {
    [`+${StyledBackground}`]: {
      outline: '$outlineInputFocus',
      borderColor: '$borderFocus',
    },
  },
});

const StyledPosition = styled('div', {
  position: 'relative',

  // This overrides the passed default Ariakit width
  'div[role="presentation"]': {
    maxWidth: '100%',
  },
});

const StyledPopover = styled(ComboboxPopover, {
  position: 'relative',
  zIndex: 4,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderWidth: '0px 1px 1px 1px',
  borderStyle: 'solid',
  borderColor: 'transparent',
  maxHeight: 'min(var(--popover-available-height,300px),300px)',
  overflow: 'auto',
  overscrollBehavior: 'contain',
});

const StyledCancelButton = styled(ComboboxCancel, InputButtonStyles, {
  zIndex: 5,
  //Override Ariakit bug
  svg: {
    pointerEvents: 'none',
  },
});

const ComboboxWrapper = styled('div', {
  position: 'relative',
  padding: 0,
  minHeight: 'auto',

  variants: {
    isOpen: {
      true: {
        [`${StyledBackground}`]: {
          zIndex: 2,
        },
        [`${StyledInput}`]: {
          zIndex: 3,
        },
      },
    },
    isInvalid: {
      true: {
        [`${StyledBackground}`]: {
          boxShadow: '$shadowError',
          borderColor: '$formBorderError',
        },
      },
      false: {},
    },
    isDisabled: {
      true: {
        [`${StyledBackground}`]: {
          boxShadow: 'none',
          outlineColor: 'transparent',
          cursor: 'not-allowed',
        },
      },
    },
  },
  compoundVariants: [
    {
      isDisabled: false,
      isInvalid: false,
      css: {
        '&:hover': {
          [`${StyledBackground}`]: {
            boxShadow: '$shadowHover',
            borderColor: '$formBorderHover',
          },
        },
      },
    },
  ],
});

const StyledNoResults = styled('div', {
  padding: '$2 $4',
});

type Props = InputBaseProps & {
  defaultValue?: string;
  options?: string[];
  filterMode?: 'external' | 'internal';
  onChange?: (value: string) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
};

const replace = (node: DOMNode) => {
  if (node && 'name' in node && node.name === 'strong') {
    const children = 'children' in node && node.children.length ? domToReact(node.children as DOMNode[]) : undefined;
    return <Text weight="bold">{children}</Text>;
  }
};

const parseText = (augmentedText: string) => <>{parse(augmentedText, { replace })}</>;

export const InputCombobox = forwardRef<HTMLInputElement, Props>(
  ({ filterMode = 'external', onChange, onBlur, options = [], defaultValue, ...inputProps }, ref) => {
    const wrapperRef = useRef<HTMLInputElement>(null);

    const overflowPadding = 8;
    const useInternalFilter = filterMode !== 'external';
    const combobox = useComboboxState({
      gutter: 0,
      sameWidth: true,
      list: useInternalFilter ? options : undefined,
      defaultValue,
      overflowPadding,
      limit: 50,
    });
    const { error, hint, label, isDisabled, isOptional, name, placeholder } = inputProps;

    const { inputId, describedBy, errorId, hintId } = useInputIds({ error, hint });
    const { clearInput, closePopup, noResults } = useI18nTranslations();

    React.useEffect(() => {
      onChange?.(combobox.value);
    }, [combobox.value, onChange]);

    const renderMatches = React.useMemo(
      () => (useInternalFilter ? combobox.matches.length && combobox.matches : options.length && options),
      [combobox.matches, options, useInternalFilter],
    );

    const closeDropdown = () => {
      combobox.setOpen(false);
      combobox.anchorRef.current?.focus();
    };

    const keyDownHandler = (e: KeyboardEvent<HTMLButtonElement>) => {
      if (['Enter', ' ', 'Escape'].includes(e.key)) {
        e.preventDefault();
        closeDropdown();
      }
    };

    const filterValues = combobox.value
      .toLowerCase()
      .split(' ')
      .filter(word => word)
      .sort((a, b) => a.length - b.length);

    const highlightMatch = (match: string) => {
      if (combobox.value === '') {
        return <Text>{match}</Text>;
      }
      const augmentedText = match
        .split(' ')
        .map(word => {
          const firstMatch = filterValues.find(el => word.toLowerCase().includes(el));
          return firstMatch ? word.replace(new RegExp(firstMatch, 'i'), `<strong>$&</strong>`) : word;
        })
        .join(' ');
      return <Text>{parseText(augmentedText)}</Text>;
    };

    useEffect(() => {
      if (!combobox.popoverRef.current) return;

      const setPopoverHeight = throttle(() => {
        if (!combobox.popoverRef.current || !wrapperRef.current) return;
        const topPopover = combobox.popoverRef.current.style.transform.includes('px, -');

        // Ariakit exposes a --popover-available-height CSS custom property
        // (https://github.com/ariakit/ariakit/blob/main/packages/ariakit/src/popover/popover-state.ts), but as InputCombobox
        // has a max-height set, we make sure the combobox actually matches the calculated available height
        //
        // A better solution would be to style the input and popover accordingly, but with the amount of borders and outlines
        // involved, this lead to a solution way more complex, style-wise.
        const popoverHeight = combobox.popoverRef.current.clientHeight || 0;
        wrapperRef.current.style.setProperty('--combobox-popover-height', `${popoverHeight}px`);

        wrapperRef.current.style.setProperty('--combobox-background-bottom', topPopover ? '0' : 'auto');
        wrapperRef.current.style.setProperty('--combobox-background-top', topPopover ? 'auto' : '0');

        wrapperRef.current.style.setProperty('--combobox-divider-bottom', topPopover ? 'auto' : '0');
        wrapperRef.current.style.setProperty('--combobox-divider-top', topPopover ? '0' : 'auto');
      }, 17); // 17ms ~= 60fps

      const popoverMutationObserver = new MutationObserver(setPopoverHeight);
      popoverMutationObserver.observe(combobox.popoverRef.current, {
        attributes: true,
        childList: true,
        subtree: true,
        attributeFilter: ['style'],
      });
    }, [combobox.popoverRef]);

    useValidationErrorEvent({ error, name }, wrapperRef);

    return (
      <StyledPosition>
        <Stack gap="2">
          <Label htmlFor={inputId} isOptional={isOptional}>
            {label}
          </Label>
          <Stack.Item grow>
            <ComboboxWrapper isInvalid={!!error} isDisabled={!!isDisabled} isOpen={combobox.open} ref={wrapperRef}>
              <StyledInputWrapper isOpen={combobox.open}>
                <StyledInput
                  state={combobox}
                  placeholder={placeholder}
                  onBlur={event => onBlur?.(event)}
                  disabled={isDisabled}
                  id={inputId}
                  aria-describedby={describedBy}
                  aria-invalid={error ? true : undefined}
                  ref={ref}
                />
              </StyledInputWrapper>
              <StyledBackground />
              <StyledPopover state={combobox}>
                {(renderMatches || []).map(value => (
                  <StyledComboboxItem key={value} value={value}>
                    {highlightMatch(value)}
                  </StyledComboboxItem>
                ))}
                {!renderMatches && combobox.value ? <StyledNoResults>{noResults}</StyledNoResults> : ''}
              </StyledPopover>
              {combobox.open && (
                <StyledCancelButton
                  state={combobox}
                  onKeyDown={keyDownHandler}
                  onClick={closeDropdown}
                  aria-label={combobox.activeId ? closePopup : clearInput}>
                  <CloseIcon size="medium" />
                </StyledCancelButton>
              )}
            </ComboboxWrapper>
            {error ? <Error id={errorId}>{error}</Error> : null}
          </Stack.Item>
          {hint ? <Hint id={hintId}>{hint}</Hint> : null}
        </Stack>
      </StyledPosition>
    );
  },
);
