import { FC } from 'react';

import { useLinkComponent } from '@link';
import { MainMenuRendering } from '@sitecore/types/manual/NavigationBar';
import { NavLink, Stack } from '@sparky';
import { useI18nTranslation } from '@sparky/providers';

import { usePlaceholderComponentContent } from '../hooks/usePlaceholderComponentContent';

const MainMenu: FC = function () {
  const mainNavigation = useI18nTranslation('mainNavigation');
  const Link = useLinkComponent();
  const content = usePlaceholderComponentContent<MainMenuRendering>('jss-main-navigation-left', 'MainMenu');

  if (!content?.fields.items) return null;

  return (
    <nav aria-label={mainNavigation}>
      <Stack as="ul" direction={{ initial: 'column', lg: 'row' }} gap={{ initial: '6', lg: '4' }}>
        {content.fields.items.map(item => (
          <li key={item.id}>
            <Link href={item.fields.link.value.href} linkType={item.fields.link.value.linktype}>
              <NavLink variant="secondary">{item.fields.link.value.text}</NavLink>
            </Link>
          </li>
        ))}
      </Stack>
    </nav>
  );
};

export default MainMenu;
