// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgPlugOffIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M10 5.76 7.13 2.89C7.36 2.37 7.89 2 8.5 2c.83 0 1.5.67 1.5 1.5v2.26ZM20 14v-4c0-1.1-.9-2-2-2h-1V3.5c0-.83-.67-1.5-1.5-1.5S14 2.67 14 3.5V8h-1.76l7.54 7.54c.13-.49.22-1 .22-1.54Zm.71 5.29-1.82-1.82L7 5.59 4.71 3.3A.996.996 0 1 0 3.3 4.71L6.59 8H6c-1.1 0-2 .9-2 2v4c0 3.31 2.69 6 6 6v1c0 .55.45 1 1 1h2c.55 0 1-.45 1-1v-1c1.3 0 2.49-.41 3.47-1.11l1.82 1.82c.2.2.45.29.71.29.26 0 .51-.1.71-.29a.996.996 0 0 0 0-1.41v-.01Z" />
  </Icon>
);
export default SvgPlugOffIcon;
SvgPlugOffIcon.displayName = 'SvgPlugOffIcon';
