import React from 'react';
import { FC } from 'react';

import { styled } from '../../stitches.config';
import { extractDataProps } from '../../util/attributes';
import { Stack } from '../Stack/Stack';
import { Strikethrough } from '../Strikethrough/Strikethrough';
import { Text } from '../Text/Text';
import { VisuallyHidden } from '../VisuallyHidden/VisuallyHidden';

export interface PriceProps {
  discountPrice: string;
  discountPriceAriaText: string;
  originalPrice: string;
  originalPriceAriaText: string;
  text?: string;
  suffixText?: string;
  size?: 'M' | 'S';
}

export const StyledPrice = styled(Text, {
  variants: {
    size: {
      M: {
        typography: '$headingM',
      },
      S: {
        typography: '$headingS',
      },
    },
  },
});

const stitchesClassName = 'sparky-price';

export const Price: FC<PriceProps> = ({
  discountPrice,
  discountPriceAriaText,
  originalPrice,
  originalPriceAriaText,
  text,
  suffixText,
  size = 'M',
  ...rest
}) => {
  const dataSet = extractDataProps(rest);

  const textSize = size === 'M' ? 'BodyL' : 'BodyM';

  return (
    <div {...dataSet} className={stitchesClassName} data-testid="price-container">
      <Stack>
        <Stack direction="row" alignY="end" gap="1">
          <StyledPrice as="p" size={size} weight="bold">
            <VisuallyHidden>{discountPriceAriaText}</VisuallyHidden>
            {discountPrice}
          </StyledPrice>
          <Text as="p" color="textLowEmphasis">
            <Strikethrough semanticDescription={originalPriceAriaText}>{originalPrice}</Strikethrough>
          </Text>
          {suffixText && (
            <Text as="p" color="textLowEmphasis">
              {suffixText}
            </Text>
          )}
        </Stack>
        {text && (
          <Text as="p" size={textSize}>
            {text}
          </Text>
        )}
      </Stack>
    </div>
  );
};

Price.toString = () => `.${stitchesClassName}`;

export default Price;
