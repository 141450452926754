import { Dispatch, FC, SetStateAction, useState } from 'react';

import usePlaceholderComponentContent from '@apps/header/shared/multilabel/src/usePlaceholderComponentContent';
import { Link } from '@dxp-next';
import { useLinkComponent } from '@link';
import { Item } from '@sitecore/types/MainMenu';
import { SubMenuRendering } from '@sitecore/types/SubMenu';
import { Box, Divider, NavLink, Stack, Stretch, TransitionSlide } from '@sparky';
import { ChevronLeftIcon, ChevronRightIcon } from '@sparky/icons';

const SelfServiceMenuMobile: FC = function () {
  const [activeSecondaryMenu, setActiveSecondaryMenu] = useState<Item | null>(null);
  const [isSecondaryMenuShown, setIsSecondaryMenuShown] = useState(false);
  const Link = useLinkComponent();
  const content = usePlaceholderComponentContent<SubMenuRendering>('jss-secondary-navigation', 'SubMenu');
  if (!content?.fields.items) return null;

  const { fields } = content;

  const closeSecondaryMenu = () => {
    setActiveSecondaryMenu(null);
    setIsSecondaryMenuShown(false);
  };

  const handleSubMenuClick = (item: Item) => {
    setIsSecondaryMenuShown(true);
    setActiveSecondaryMenu(item);
  };

  return (
    <>
      <Stack as="ul" direction={'column'} gap={6}>
        {fields.items.map(item => {
          const isSubMenu = item.fields.items?.length;
          return (
            <li key={item.id}>
              <Stretch width>
                {isSubMenu ? (
                  <NavLink
                    onClick={() => handleSubMenuClick(item)}
                    rightIcon={<ChevronRightIcon />}
                    justifyContent="spaceBetween"
                    variant="secondary"
                    isCurrent={item.active}>
                    {item.fields.link.value.text}
                  </NavLink>
                ) : (
                  <Link href={item.fields.link.value.href} linkType={item.fields.link.value.linktype}>
                    <NavLink variant="secondary" isCurrent={item.active}>
                      {item.fields.link.value.text}
                    </NavLink>
                  </Link>
                )}
              </Stretch>
            </li>
          );
        })}
        <Divider />
      </Stack>
      {activeSecondaryMenu && (
        <SecondaryMenu
          isSecondaryMenuShown={isSecondaryMenuShown}
          setIsSecondaryMenuShown={setIsSecondaryMenuShown}
          closeSecondaryMenu={closeSecondaryMenu}
          activeSecondaryMenu={activeSecondaryMenu}
        />
      )}
    </>
  );
};

interface SecondaryMenuProps {
  isSecondaryMenuShown: boolean;
  setIsSecondaryMenuShown: Dispatch<SetStateAction<boolean>>;
  closeSecondaryMenu: () => void;
  activeSecondaryMenu: Item | null;
}

const SecondaryMenu: FC<SecondaryMenuProps> = ({
  isSecondaryMenuShown,
  setIsSecondaryMenuShown,
  closeSecondaryMenu,
  activeSecondaryMenu,
}) => (
  <TransitionSlide isEntering={isSecondaryMenuShown} isAbsolute={true} onLeaveFinished={closeSecondaryMenu}>
    <Box paddingX="5" paddingY="4" backgroundColor="backgroundPrimary">
      <Box paddingY="6">
        <Stretch width={true}>
          <NavLink
            isCurrent={activeSecondaryMenu?.active}
            variant={'secondary'}
            onClick={() => setIsSecondaryMenuShown(false)}
            leftIcon={<ChevronLeftIcon />}>
            {activeSecondaryMenu?.fields?.link.value.text}
          </NavLink>
        </Stretch>
      </Box>
      <Divider />
      <Box paddingY="6">
        <Stack gap="6" as="ul">
          {activeSecondaryMenu?.fields.items
            ?.filter(item => item.fields?.link?.value?.text)
            .map((item, key) => {
              const { text, href, linktype } = item.fields.link.value;

              return (
                <li key={key}>
                  <Stretch width={true}>
                    <Link href={href} linkType={linktype}>
                      <NavLink isCurrent={item.active} variant={'secondary'}>
                        {text}
                      </NavLink>
                    </Link>
                  </Stretch>
                </li>
              );
            })}
        </Stack>
      </Box>
    </Box>
  </TransitionSlide>
);

export default SelfServiceMenuMobile;
