import React, { FC } from 'react';

import { buttonRecipe } from '../components/Button/Button.css';
import { styled } from '../stitches.config';
import { iconColors, feedbackColors, graphsColors } from '../themes/eneco/tokens';
import { DynamicVariantProp, TransformStitchesToSparky } from '../types';
import { createVariants, extractCssProps, extractVariantProps } from '../util/css/stitches';

export const SvgIcon = styled('svg', {
  flexShrink: 0,

  [`.${buttonRecipe.classNames.base} &`]: {
    fill: 'currentColor',
  },

  variants: {
    /** Color of the icon */
    color: createVariants({ ...iconColors, ...feedbackColors, ...graphsColors }, 'fill'),
    /** The size of the `Icon`, based on the `iconSizes` tokens. Default is `medium`.*/
    size: {
      small: {
        iconography: '$small',
      },
      medium: {
        iconography: '$medium',
      },
      large: {
        iconography: '$large',
      },
      extraLarge: {
        iconography: '$extraLarge',
      },
    },
  },
  defaultVariants: {
    size: 'medium',
    color: 'iconPrimary',
  },
});

const ownClassName = 'icon-sparky';

export const Icon: FC<React.PropsWithChildren<IconProps>> = ({ children, color, size, verticalAlign = 'bottom' }) => {
  const cssProps = extractCssProps({ verticalAlign });
  const variantProps = extractVariantProps({ size, color });
  return (
    <SvgIcon {...variantProps} aria-hidden="true" className={ownClassName} css={cssProps} viewBox="0 0 24 24">
      {children}
    </SvgIcon>
  );
};

export type IconProps = {
  verticalAlign?: DynamicVariantProp<'verticalAlign'>;
} & TransformStitchesToSparky<typeof SvgIcon>;

Icon.toString = () => `.${ownClassName}`;
