import { useId, FC, ReactElement } from 'react';

import type { RadioGroupItemProps } from '@radix-ui/react-radio-group';
import reactToText from 'react-to-text';

import { styled } from '../../../stitches.config';
import { hasComponent } from '../../../util/children';
import { Image as SparkyImage } from '../../Image/Image';
import { Stack } from '../../Stack/Stack';
import { RadioBase } from '../RadioBase';
import { useRadioGroup } from '../RadioGroup/RadioGroup';
import { RadioIndicator } from '../RadioIndicator';

const StyledRadio = styled(RadioBase, {
  marginTop: '2px', // Custom value; with top alignment it doesn't look right without spacing

  '&:focus-visible': {
    outline: '$outlineFocus',
    borderColor: '$borderFocus',
  },

  '@supports not selector(:focus-visible)': {
    '&:focus': {
      outline: '$outlineFocus',
      borderColor: '$borderFocus',
    },
  },
});

const StyledChildren = styled('div', {
  display: 'flex',

  variants: {
    alignLabel: {
      center: {
        margin: '0 auto',
      },
      start: {
        // No styles needed
      },
    },
  },
});

/**
 * Label element for the input.
 */
const Label = styled('label', {
  cursor: 'pointer',

  '&:hover': {
    [`& ${StyledRadio}`]: {
      boxShadow: '0 0 0 $borderWidths$l $colors$outlineHover',
      borderColor: '$formBorderHover',
    },
  },
});

type ImageProps = Pick<
  Parameters<typeof SparkyImage>[0],
  'alt' | 'height' | 'maxHeight' | 'maxWidth' | 'src' | 'width'
>;

const Image: FC<ImageProps> = ({ ...props }) => <SparkyImage {...props} objectFit="contain"></SparkyImage>;

interface ButtonProps extends Pick<RadioGroupItemProps, 'value'> {
  alignLabel?: 'center' | 'start';
  children: ReactElement<typeof Image> | string;
}

interface CompoundButtonProps {
  Image: typeof Image;
}

export const RadioButton: FC<ButtonProps> & CompoundButtonProps = ({ alignLabel = 'start', children, value }) => {
  const id = useId();
  const { ariaDescribedby } = useRadioGroup();

  const hasImage = hasComponent(children, Image);

  return (
    <Label data-label={reactToText(children)} htmlFor={id}>
      <Stack gap="2" direction="row" alignY={hasImage ? 'center' : 'start'}>
        <Stack.Item shrink={false}>
          <StyledRadio aria-describedby={ariaDescribedby} id={id} value={value}>
            <RadioIndicator />
          </StyledRadio>
        </Stack.Item>
        <StyledChildren alignLabel={alignLabel}>{children}</StyledChildren>
      </Stack>
    </Label>
  );
};

StyledRadio.displayName = 'styled(RadioBase)';
Label.displayName = 'styled(label)';

RadioButton.Image = Image;
RadioButton.Image.displayName = 'RadioButton.Image';
