import React, { AnchorHTMLAttributes } from 'react';

import ReactIs from 'react-is';

import { Icon, ChevronRightIcon } from '../../icons';
import { useI18nTranslations } from '../../providers/i18n';
import { styled } from '../../stitches.config';
import { Stack } from '../Stack/Stack';
import { VisuallyHidden, visuallyHiddenStyle } from '../VisuallyHidden/VisuallyHidden';

//For situations in which only a number is shown, expand the clickable area beyond the label.
const minWidthSpacer = {
  paddingLeft: '$4',
  paddingRight: '$4',
  marginLeft: '-$4',
  marginRight: '-$4',
  minWidth: '$targetMinWidth',
};

const ProgressIndicatorLabel = styled('span', {
  textDecorationLine: 'none',
  fontWeight: '$bodyBold',
  typography: '$bodyM',
  color: '$linkSecondary',
  whiteSpace: 'pre',
  background: 'transparent',
  borderBottom: '$borderWidths$m solid transparent',
  paddingTop: '$3',
  paddingBottom: 'calc($space$3 - $borderWidths$m)',
  display: 'flex',
  alignItems: 'center',

  '&::before': {
    content: 'counter(listItem)',
    margin: 'auto',
    '@md': {
      content: 'counter(listItem) ". "',
    },
  },

  '&:focus-visible': {
    outline: '$outlineFocus',
  },

  '@supports not selector(:focus-visible)': {
    '&:focus': {
      outline: '$outlineFocus',
    },
  },

  '@safeMotion': {
    transition: 'color $easeQuick, border $easeQuick',
  },

  variants: {
    isActive: {
      true: {
        color: '$linkBrand',
        borderBottomColor: '$linkBrand',
        '&::before': {
          '@sm': {
            content: 'counter(listItem) ". "',
          },
        },
      },
      false: {},
    },

    isDisabled: {
      true: {
        color: '$linkDisabled',
      },
      false: {},
    },
    hideInactiveLabel: {
      true: {},
    },
  },
  compoundVariants: [
    {
      isActive: false,
      isDisabled: false,
      css: {
        '@mdMax': minWidthSpacer,
        '&:hover': {
          color: '$linkPrimary',
          borderBottomColor: '$borderFocus',
        },
      },
    },
    {
      isActive: false,
      hideInactiveLabel: true,
      css: {
        '&::before': {
          content: 'counter(listItem)',
        },
      },
    },
    {
      isActive: false,
      isDisabled: false,
      hideInactiveLabel: true,
      css: {
        ...minWidthSpacer,
      },
    },
  ],

  defaultVariants: {
    isActive: false,
    isDisabled: false,
    hideInactiveLabel: false,
  },
});

const Item = styled('li', {
  listStyle: 'none',

  '&:last-of-type': {
    [`& ${Icon}`]: {
      display: 'none',
    },
  },

  //Set the icon color
  color: '$progressIndicatorItemDefault',

  variants: {
    isDisabled: {
      true: {
        color: '$progressIndicatorItemDisabled',
      },
    },
    isActive: {
      true: {
        color: '$progressIndicatorItemDisabled',
      },
    },
  },
});

const ProgressIndicatorText = styled('div', {
  variants: {
    isActive: {
      true: {},
      false: {},
    },
    hideInactiveLabel: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      isActive: false,
      hideInactiveLabel: true,
      css: visuallyHiddenStyle,
    },
    {
      isActive: true,
      hideInactiveLabel: false,
      css: { '@smMax': visuallyHiddenStyle },
    },
    {
      isActive: false,
      hideInactiveLabel: false,
      css: { '@mdMax': visuallyHiddenStyle },
    },
  ],
  defaultVariants: {
    isActive: false,
    hideInactiveLabel: false,
  },
});

const StyledProgressIndicator = styled('nav', {
  li: {
    counterIncrement: 'listItem',
  },
});

interface ProgressIndicatorItemProps
  extends Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'onClick' | 'onMouseEnter'> {
  /** Link text. */
  children: string;
  /** Indicates whether the step is currently active. */
  isActive?: boolean;
  /** Indicates whether the step is not yet accessible. */
  isDisabled?: boolean;
  /** Hide inactive labels. This prop can only be inserted programatically */
  hideInactiveLabel?: never;
  /** Allow overwrite the anchore tag, when the href property is not required and the onClick is used to
   * execute the navigation.
   */
  as?: 'a' | 'span';
}

const ProgressIndicatorItem = React.forwardRef<HTMLAnchorElement, ProgressIndicatorItemProps>((props, ref) => {
  const { children, isActive, isDisabled, hideInactiveLabel, ...linkProps } = props;
  const { current, completed } = useI18nTranslations();

  return (
    <Item isActive={isActive} isDisabled={isDisabled}>
      <Stack direction="row" gap={{ initial: '3', md: '5' }} alignX="justify" alignY="center">
        {isDisabled || isActive ? (
          <ProgressIndicatorLabel
            isActive={isActive}
            isDisabled={isDisabled}
            aria-disabled={isDisabled}
            hideInactiveLabel={hideInactiveLabel}>
            <ProgressIndicatorText isActive={isActive} hideInactiveLabel={hideInactiveLabel}>
              {/* To prevent React's NotFoundError during node insertion,
               we maintain a consistent DOM structure,
               by always rendering the same components and controlling their visibility with styles.
               See: https://github.com/facebook/react/issues/11538 */}
              <span style={{ visibility: isActive ? 'visible' : 'hidden' }}>
                {<VisuallyHidden>{`${current}: `}</VisuallyHidden>}
              </span>
              {children}
            </ProgressIndicatorText>
          </ProgressIndicatorLabel>
        ) : (
          <ProgressIndicatorLabel as="a" {...linkProps} hideInactiveLabel={hideInactiveLabel} ref={ref}>
            <ProgressIndicatorText hideInactiveLabel={hideInactiveLabel}>{children}</ProgressIndicatorText>
            <VisuallyHidden>{`(${completed})`}</VisuallyHidden>
          </ProgressIndicatorLabel>
        )}
        <ChevronRightIcon size="small" color="currentColor" />
      </Stack>
    </Item>
  );
});

interface Props {
  className?: never;
}

const ProgressIndicatorComponent = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>(
  ({ className, children }, ref) => {
    const { steps } = useI18nTranslations();
    const childrenCount = React.Children.count(children);

    const hideInactiveLabels = childrenCount >= 6;
    let augmentedChildren;

    if (hideInactiveLabels) {
      augmentedChildren = React.Children.map(children, child => {
        if (ReactIs.isElement(child)) {
          return React.cloneElement(child, { hideInactiveLabel: true });
        }

        return child;
      });
    }

    return (
      <StyledProgressIndicator aria-label={steps}>
        <Stack
          as={'ol'}
          direction={'row'}
          gap={{ initial: '3', md: '5' }}
          alignX="center"
          className={className}
          ref={ref}>
          {augmentedChildren ?? children}
        </Stack>
      </StyledProgressIndicator>
    );
  },
);

ProgressIndicatorLabel.displayName = 'ProgressIndicatorLabel';
Item.displayName = 'Item';
ProgressIndicatorText.displayName = 'ProgressIndicatorText';
StyledProgressIndicator.displayName = 'styled(ProgressIndicator)';
ProgressIndicatorItem.displayName = 'ProgressIndicator.Item';
ProgressIndicatorComponent.displayName = 'ProgressIndicator';

// Compound components with forwardRef cause issues: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/34757#issuecomment-488848720
export const ProgressIndicator = Object.assign({}, ProgressIndicatorComponent, { Item: ProgressIndicatorItem });
