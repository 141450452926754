import React, { FC, useCallback, useEffect, useState } from 'react';

import * as Dialog from '@radix-ui/react-dialog';

import { CloseIcon, HamburgerMenuIcon, ProfileIcon } from '../../icons';
import { useI18nTranslations } from '../../providers/i18n';
import { keyframes, styled } from '../../stitches.config';
import { Box } from '../Box/Box';
import { Divider } from '../Divider/Divider';
import { buttonStyle } from '../IconButton/IconButton';
import { Stack } from '../Stack/Stack';
import { Text } from '../Text/Text';

const Header = styled(Stack, {
  padding: '$1',
  paddingTop: 0,
  paddingBottom: 0,
  minHeight: '59px',
  backgroundColor: '$backgroundPrimary',
});

const MenuSlideIn = keyframes({
  '0%': {
    transform: 'translateX(-100vw)',
    opacity: 0,
  },
  '100%': {
    transform: 'translateX(0)',
    opacity: 1,
  },
});

const MenuSlideOut = keyframes({
  '0%': {
    transform: 'translateX(0)',
    opacity: 1,
  },
  '100%': {
    transform: 'translateX(-100vw)',
    opacity: 0,
  },
});

const StyledContent = styled(Dialog.Content, {
  position: 'fixed',
  overflow: 'auto',
  top: 0,
  left: 0,
  height: '100vh',
  dvh: 100,
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  zIndex: 1,
});

const StyledMenu = styled('div', {
  backgroundColor: '$backgroundPrimary',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  transform: 'translateX(-100%)',

  $$animationDuration: '0.01ms',
  '@safeMotion': {
    $$animationDuration: '.3s',
    transition: '$easeMedium',
  },

  variants: {
    isActive: {
      true: {
        animation: `${MenuSlideIn}  $$animationDuration ease-in forwards`,
      },
      false: {
        animation: `${MenuSlideOut}  $$animationDuration ease-in forwards`,
      },
    },
  },
});

const StyledMain = styled(Box, {
  flexGrow: 1,
});

export const sharedPlainButtonStyles = {
  ...buttonStyle,
  padding: '$2',
  borderRadius: '$m',
  color: 'inherit',
};

const StyledPlainButton = styled('button', sharedPlainButtonStyles);
const StyledLinkButton = styled('a', sharedPlainButtonStyles);

type CompoundMobileHeaderProps = FC<{
  /** Controlled state of the Profile Menu */
  isProfileMenuOpen: boolean;
  /** External setter that sets setProfileMenuOpen */
  setProfileMenuOpen: (open: boolean) => void;
  /** Controlled state of the Hamburger Menu */
  isHamburgerMenuOpen: boolean;
  /** External setter that sets isHamburgerMenuOpen */
  setHamburgerMenuOpen: (open: boolean) => void;
  /** Content that will be shown inside the Hamburger menu */
  children: React.ReactNode;
  /** Logo that links back to the homepage, which should have an accessible description. */
  logoLink: React.ReactNode;
  isLoggedIn?: boolean;
  profileLink?: {
    href: string;
    text: string;
  };
  hamburgerMenuLabel?: string;
  profileMenuLabel?: string;
  languageSelector?: React.ReactNode;
  languageSelectorOutsideMenu?: boolean;
}> & {
  HamburgerMenu: typeof HamburgerMenu;
  Top: typeof Top;
  Main: typeof Main;
  Bottom: typeof Bottom;
};

export const MobileHeader: CompoundMobileHeaderProps = ({
  children,
  isProfileMenuOpen,
  setProfileMenuOpen,
  isHamburgerMenuOpen,
  setHamburgerMenuOpen,
  logoLink,
  isLoggedIn,
  profileLink,
  hamburgerMenuLabel,
  profileMenuLabel,
  languageSelector,
  languageSelectorOutsideMenu,
}) => {
  const [isAnyMenuOpen, setIsAnyMenuOpen] = useState(isHamburgerMenuOpen || isProfileMenuOpen);
  const { languageNavigation } = useI18nTranslations();

  const onToggleHamburgerMenu = () => {
    setHamburgerMenuOpen(!isHamburgerMenuOpen);
  };

  const onToggleProfileMenu = () => {
    setProfileMenuOpen(!isProfileMenuOpen);
  };

  useEffect(() => {
    if (!isProfileMenuOpen && !isHamburgerMenuOpen && isAnyMenuOpen) {
      setTimeout(() => {
        setIsAnyMenuOpen(false);
      }, 300);
    }
    if (isHamburgerMenuOpen || isProfileMenuOpen) {
      setIsAnyMenuOpen(true);
    }
  }, [isHamburgerMenuOpen, isProfileMenuOpen, isAnyMenuOpen]);

  const ProfileButtonContent = () => {
    return (
      <Stack alignX="center">
        <ProfileIcon />
        {profileMenuLabel && (
          <Text weight="bold" size="BodyXS">
            {profileMenuLabel}
          </Text>
        )}
      </Stack>
    );
  };

  const HamburgerMenuContent = () => (
    <Stack alignX="center">
      {isHamburgerMenuOpen ? <CloseIcon /> : <HamburgerMenuIcon />}
      {hamburgerMenuLabel && (
        <Text weight="bold" size="BodyXS">
          {hamburgerMenuLabel}
        </Text>
      )}
    </Stack>
  );

  return (
    <Dialog.Root open={isAnyMenuOpen}>
      <Header alignX="justify" alignY="center" direction="row">
        <Dialog.Trigger asChild>
          <StyledPlainButton onClick={onToggleHamburgerMenu}>
            <HamburgerMenuContent />
          </StyledPlainButton>
        </Dialog.Trigger>

        {logoLink}

        <Stack direction="row" alignY="center">
          {languageSelector && (
            <nav aria-label={languageNavigation}>
              <Stack direction="row" gap="5" alignX="end" alignY="center" as="ul">
                {languageSelector}
              </Stack>
            </nav>
          )}

          {isLoggedIn || !profileLink ? (
            <Dialog.Trigger asChild>
              <StyledPlainButton onClick={onToggleProfileMenu}>
                <ProfileButtonContent />
              </StyledPlainButton>
            </Dialog.Trigger>
          ) : (
            <StyledLinkButton href={profileLink.href}>
              <ProfileButtonContent />
            </StyledLinkButton>
          )}
        </Stack>
      </Header>
      <Divider />

      <Dialog.Portal>
        <Dialog.Overlay>
          <StyledContent>
            {!isProfileMenuOpen && (
              <>
                <Header alignX="justify" alignY="center" direction="row">
                  <Dialog.Trigger asChild>
                    <StyledPlainButton onClick={onToggleHamburgerMenu}>
                      <HamburgerMenuContent />
                    </StyledPlainButton>
                  </Dialog.Trigger>
                  {logoLink}
                  {profileLink && (
                    <Stack direction={'row'} alignY={'center'}>
                      {languageSelectorOutsideMenu && languageSelector && languageSelector}
                      <Dialog.Trigger asChild>
                        <StyledPlainButton onClick={onToggleProfileMenu}>
                          <ProfileButtonContent />
                        </StyledPlainButton>
                      </Dialog.Trigger>
                    </Stack>
                  )}
                </Header>
                <Divider />
              </>
            )}
            {children}
          </StyledContent>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

type HamburgerProps = {
  children: React.ReactNode;
  isOpen: boolean;
};

const HamburgerMenu: FC<React.PropsWithChildren<HamburgerProps>> = ({ children, isOpen }) => {
  const [isVisible, setIsVisible] = useState(isOpen);

  useEffect(() => {
    if (isOpen && !isVisible) {
      setIsVisible(true);
    }

    isOpen && window.scrollTo(0, 0);
  }, [isOpen, isVisible]);

  const onAnimationEnd = useCallback(() => {
    if (!isOpen) setIsVisible(false);
  }, [isOpen]);

  if (!isVisible) {
    return null;
  }
  return (
    <StyledMenu data-cy="dropMenu" isActive={isOpen} onAnimationEnd={onAnimationEnd}>
      {children}
    </StyledMenu>
  );
};

type TopsiteProps = {
  children: React.ReactNode;
};

const Top: FC<React.PropsWithChildren<TopsiteProps>> = ({ children }) => {
  const { domainNavigation } = useI18nTranslations();

  return (
    <Box paddingX="6" paddingY="4">
      <nav aria-label={domainNavigation}>
        <Stack direction="row" gap="4" as="ul">
          {children}
        </Stack>
      </nav>
    </Box>
  );
};

type MainProps = {
  children: React.ReactNode;
};

const Main: FC<React.PropsWithChildren<MainProps>> = ({ children }) => {
  return (
    <StyledMain paddingX="10" paddingY="3">
      {children}
    </StyledMain>
  );
};

type BottomProps = {
  children: React.ReactNode;
};

const StyledBottom = styled(Stack, {
  // Any children that aren't placeholders
  '& > :not([data-scope])': {
    margin: '$3 $6',
  },
  // Any non-empty placeholders
  '& > [data-scope] > *': {
    margin: '$3 $6',
  },
});

const Bottom: FC<React.PropsWithChildren<BottomProps>> = ({ children }) => (
  <>
    <Divider />
    <StyledBottom direction="row" alignX="end">
      {children}
    </StyledBottom>
  </>
);

MobileHeader.Bottom = Bottom;
MobileHeader.HamburgerMenu = HamburgerMenu;
MobileHeader.Main = Main;
MobileHeader.Top = Top;

Header.displayName = 'Header';
MobileHeader.Bottom.displayName = 'MobileHeader.Bottom';
MobileHeader.HamburgerMenu.displayName = 'MobileHeader.HamburgerMenu';
MobileHeader.Main.displayName = 'MobileHeader.Main';
MobileHeader.Top.displayName = 'MobileHeader.Top';
StyledContent.displayName = 'styled(Dialog.Content)';
StyledMenu.displayName = 'styled(Menu)';
