import React, { ReactNode } from 'react';

import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';

import { overlayStyles, StyledPageGrid, contentStyles, ScrollContainer, SharedProps } from './Dialog';
import { styled } from '../../stitches.config';
import { TransformStitchesToSparky } from '../../types';
import { Box } from '../Box/Box';
import { Button } from '../Button/Button';
import { Heading } from '../Heading/Heading';
import { Stack } from '../Stack/Stack';

const StyledScrollableOverlay = styled('div', overlayStyles);
const StyledOverlay = styled(AlertDialogPrimitive.Overlay, overlayStyles);

const StyledContent = styled(AlertDialogPrimitive.Content, contentStyles, { padding: '$6' });

type AlertDialogVariants = TransformStitchesToSparky<typeof StyledContent>;

type BaseProps = {
  /** Determines whether the user is able to interact (scroll) with the background */
  disableBackgroundInteraction?: boolean;
  /** Optional custom footer content to replace default buttons */
  footer?: ReactNode;
} & SharedProps &
  AlertDialogVariants;

type Props = BaseProps &
  (
    | {
        footer: ReactNode;
        onConfirm?: never;
        onDeny?: never;
        confirmText?: never;
        denyText?: never;
      }
    | {
        /** Optional custom footer content to replace default buttons */
        footer?: never;
        /** Function that is triggerered when the user confirms the action */
        onConfirm: React.MouseEventHandler<HTMLButtonElement>;
        /** Function that is triggerered when the user denies the action */
        onDeny: React.MouseEventHandler<HTMLButtonElement>;
        /** Text that is shown on the button that confirms the action */
        confirmText: string;
        /** Text that is shown on the button that denies the action */
        denyText: string;
      }
  );

export const AlertDialog: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  description,
  width,
  isOpen,
  setOpen,
  children,
  onConfirm,
  onDeny,
  confirmText,
  denyText,
  trigger,
  footer = undefined,
  disableBackgroundInteraction = true,
}) => {
  const Overlay = disableBackgroundInteraction ? StyledOverlay : StyledScrollableOverlay;

  return (
    <AlertDialogPrimitive.Root onOpenChange={setOpen} open={isOpen}>
      {trigger ? <AlertDialogPrimitive.Trigger asChild>{trigger}</AlertDialogPrimitive.Trigger> : null}
      <AlertDialogPrimitive.Portal>
        <Overlay>
          <StyledPageGrid>
            <StyledContent width={width} onEscapeKeyDown={event => event.preventDefault()}>
              <ScrollContainer overflow="auto">
                {title && (
                  <Box paddingBottom={{ initial: '2', md: '4' }}>
                    <AlertDialogPrimitive.Title asChild>
                      {typeof title === 'string' ? (
                        <Heading as="h2" size="S">
                          {title}
                        </Heading>
                      ) : (
                        title
                      )}
                    </AlertDialogPrimitive.Title>
                  </Box>
                )}
                {description && (
                  <AlertDialogPrimitive.Description asChild>
                    <Box paddingBottom={{ initial: '4', md: '5' }}>{description}</Box>
                  </AlertDialogPrimitive.Description>
                )}
                {children}
              </ScrollContainer>
              <Box paddingTop="6">
                {footer ?? (
                  <Stack direction={{ initial: 'column', md: 'row' }} gap="5">
                    <AlertDialogPrimitive.Action asChild>
                      <Button onClick={onConfirm}>{confirmText}</Button>
                    </AlertDialogPrimitive.Action>
                    <AlertDialogPrimitive.Cancel asChild>
                      <Button onClick={onDeny} action="secondary">
                        {denyText}
                      </Button>
                    </AlertDialogPrimitive.Cancel>
                  </Stack>
                )}
              </Box>
            </StyledContent>
          </StyledPageGrid>
        </Overlay>
      </AlertDialogPrimitive.Portal>
    </AlertDialogPrimitive.Root>
  );
};

StyledOverlay.toString = () => '.alert-dialog-overlay';
StyledOverlay.displayName = 'styled(Overlay)';
StyledScrollableOverlay.toString = () => '.alert-dialog-scrollable-overlay';
StyledScrollableOverlay.displayName = 'styled(ScrollableOverlay)';
StyledContent.displayName = 'styled(DialogContent)';
