// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgRentalDeviceIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M8.01 20h2v1c0 .55-.45 1-1 1s-1-.45-1-1v-1Zm6 1c0 .55.45 1 1 1s1-.45 1-1v-1h-2v1ZM18 3v15c0 .55-.45 1-1 1H7c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h10c.55 0 1 .45 1 1Zm-6 12H8v2h4v-2Zm4 1c0-.55-.45-1-1-1s-1 .45-1 1 .45 1 1 1 1-.45 1-1Z" />
  </Icon>
);
export default SvgRentalDeviceIcon;
SvgRentalDeviceIcon.displayName = 'SvgRentalDeviceIcon';
