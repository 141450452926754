import React, { FC } from 'react';

import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const SwimmingPool: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path d="m20.667 36.5c0.481 0 0.9328 0.2306 1.2148 0.62 1.9948 2.7536 5.1318 4.38 8.452 4.38 3.32 0 6.4572-1.6264 8.4518-4.38 0.2822-0.3894 0.7338-0.62 1.2148-0.62s0.9326 0.2306 1.2148 0.62c1.9946 2.7536 5.1318 4.38 8.4518 4.38 3.3202 0 6.4572-1.6264 8.452-4.38 0.282-0.3894 0.7338-0.62 1.2148-0.62 0.4808 0 0.9326 0.2306 1.2148 0.62 1.9946 2.7536 5.1316 4.38 8.4518 4.38 0.8284 0 1.5 0.6716 1.5 1.5s-0.6716 1.5-1.5 1.5c-3.676 0-7.1476-1.5352-9.6666-4.1642-2.5192 2.629-5.9908 4.1642-9.6668 4.1642-3.6758 0-7.1476-1.5352-9.6666-4.1642-2.519 2.629-5.9908 4.1642-9.6666 4.1642-3.676 0-7.1476-1.5352-9.6668-4.1642-2.519 2.629-5.9906 4.1642-9.6666 4.1642-0.82842 0-1.5-0.6716-1.5-1.5s0.67158-1.5 1.5-1.5c3.3202 0 6.4572-1.6264 8.4519-4.38 0.2821-0.3894 0.7339-0.62 1.2147-0.62z" />
    <path d="m20.667 49.5c0.481 0 0.9328 0.2306 1.2148 0.62 1.9948 2.7536 5.1318 4.38 8.452 4.38 3.32 0 6.4572-1.6264 8.4518-4.38 0.2822-0.3894 0.7338-0.62 1.2148-0.62s0.9326 0.2306 1.2148 0.62c1.9946 2.7536 5.1318 4.38 8.4518 4.38 3.3202 0 6.4572-1.6264 8.452-4.38 0.282-0.3894 0.7338-0.62 1.2148-0.62 0.4808 0 0.9326 0.2306 1.2148 0.62 1.9946 2.7536 5.1316 4.38 8.4518 4.38 0.8284 0 1.5 0.6716 1.5 1.5s-0.6716 1.5-1.5 1.5c-3.676 0-7.1476-1.5352-9.6666-4.1642-2.5192 2.629-5.9908 4.1642-9.6668 4.1642-3.6758 0-7.1476-1.5352-9.6666-4.1642-2.519 2.629-5.9908 4.1642-9.6666 4.1642-3.676 0-7.1476-1.5352-9.6668-4.1642-2.519 2.629-5.9906 4.1642-9.6666 4.1642-0.82842 0-1.5-0.6716-1.5-1.5s0.67158-1.5 1.5-1.5c3.3202 0 6.4572-1.6264 8.4519-4.38 0.2821-0.3894 0.7339-0.62 1.2147-0.62z" />
    <path d="m20.667 62.5c0.481 0 0.9328 0.2306 1.2148 0.62 1.9948 2.7536 5.1318 4.38 8.452 4.38 3.32 0 6.4572-1.6264 8.4518-4.38 0.2822-0.3894 0.7338-0.62 1.2148-0.62s0.9326 0.2306 1.2148 0.62c1.9946 2.7536 5.1318 4.38 8.4518 4.38 3.3202 0 6.4572-1.6264 8.452-4.38 0.282-0.3894 0.7338-0.62 1.2148-0.62 0.4808 0 0.9326 0.2306 1.2148 0.62 1.9946 2.7536 5.1316 4.38 8.4518 4.38 0.8284 0 1.5 0.6716 1.5 1.5s-0.6716 1.5-1.5 1.5c-3.676 0-7.1476-1.5352-9.6666-4.1642-2.5192 2.629-5.9908 4.1642-9.6668 4.1642-3.6758 0-7.1476-1.5352-9.6666-4.1642-2.519 2.629-5.9908 4.1642-9.6666 4.1642-3.676 0-7.1476-1.5352-9.6668-4.1642-2.519 2.629-5.9906 4.1642-9.6666 4.1642-0.82842 0-1.5-0.6716-1.5-1.5s0.67158-1.5 1.5-1.5c3.3202 0 6.4572-1.6264 8.4519-4.38 0.28212-0.3894 0.7339-0.62 1.2147-0.62z" />
    <path d="m41.5 14.5c0-1.6569 1.3432-3 3-3s3 1.3431 3 3v1.5h3v-1.5c0-3.3137-2.6862-6-6-6s-6 2.6863-6 6v24h3v-3.5h12v6.5h3v-27c0-1.6569 1.3432-3 3-3s3 1.3431 3 3v1.5h3v-1.5c0-3.3137-2.6862-6-6-6s-6 2.6863-6 6v7.5h-12zm12 17.5h-12v-7h12z" />
  </Illustration>
);
