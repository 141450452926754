// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgSplitterIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M12 4c-.6 0-1 .4-1 1v7.4l5.3 5.3-1.4 1.4c-.3.3 0 .9.4.9h4.3c.3 0 .5-.2.5-.5v-4.3c0-.4-.5-.7-.9-.4l-1.4 1.4-4.7-4.7V4.9c0-.6-.4-1-1-1L12 4ZM7.7 17.7l1.4 1.4c.3.3 0 .9-.4.9H4.4c-.3 0-.5-.2-.5-.5v-4.3c0-.4.5-.7.9-.4l1.4 1.4 3-3 1.4 1.4-3 3 .1.1Z" />
  </Icon>
);
export default SvgSplitterIcon;
SvgSplitterIcon.displayName = 'SvgSplitterIcon';
