import React, { FC } from 'react';

import { styled } from '../../stitches.config';
import { TransformStitchesToSparky } from '../../types';
import { extractVariantProps } from '../../util/css/stitches';
import { Box } from '../Box/Box';

type CardVariants = TransformStitchesToSparky<typeof StyledCard>;
interface CardProps extends CardVariants {
  as?: Extract<keyof JSX.IntrinsicElements, 'section' | 'div'>;
  className?: never;
}

interface CompoundCardProps {
  Content: typeof Content;
}

export const StyledCard = styled('div', {
  backgroundColor: '$backgroundPrimary',
  boxShadow: '$s',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  variants: {
    corners: {
      square: {
        borderRadius: 0,
      },
      rounded: {
        borderRadius: '$m',
      },
    },
    elevation: {
      S: {
        boxShadow: '$s',
      },
      L: {
        boxShadow: '$l',
      },
    },
    overflow: {
      hidden: {
        overflow: 'hidden',
      },
    },
  },
  defaultVariants: {
    corners: 'rounded',
  },
});

const sparkyClassName = 'sparky-card';

export const Card: FC<React.PropsWithChildren<CardProps>> & CompoundCardProps = ({
  children,
  as,
  corners,
  elevation = 'S',
  overflow,
  className = '',
}) => {
  const variantProps = extractVariantProps({ corners, elevation, overflow });

  return (
    <StyledCard as={as} {...variantProps} className={`${sparkyClassName} ${className}`}>
      {children}
    </StyledCard>
  );
};

Card.toString = () => `.${sparkyClassName}`;
StyledCard.displayName = 'styled(Card)';
Card.displayName = 'Card';

const Content: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <Box padding="10">{children}</Box>;
};

Card.Content = Content;
Card.Content.displayName = 'Card.Content';
