// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgSourceHeatIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M3.006 3c0-.55.45-1 .999-1h15.99c.55 0 1 .45 1 1s-.45.999-1 .999H4.005c-.55 0-1-.45-1-1Zm9.704 3.288a.995.995 0 1 0-1.41 1.41c.28.289 2.689 2.898 0 5.586-3.327 3.329-1.459 6.957 0 8.416.19.2.45.29.71.29a.995.995 0 0 0 .7-1.7c-.28-.289-2.689-2.898 0-5.586 3.328-3.329 1.459-6.957 0-8.416Zm6.505.08a.989.989 0 0 0-1.399-.14c-.43.35-.49.98-.15 1.41.12.15 2.879 3.627.07 6.436-1.309 1.31-1.928 2.699-1.838 4.138.13 2.149 1.858 3.518 1.928 3.578a1.002 1.002 0 0 0 1.4-.17.999.999 0 0 0-.16-1.4c-.01 0-1.1-.889-1.17-2.138-.05-.84.38-1.71 1.26-2.589 3.268-3.268 1.479-7.396.07-9.115l-.01-.01ZM8.102 9.786c-.13-2.149-1.858-3.518-1.928-3.578a.999.999 0 0 0-1.24 1.57c.01 0 1.1.889 1.17 2.138.05.84-.38 1.71-1.26 2.589-3.268 3.268-1.479 7.396-.07 9.115a1 1 0 0 0 1.4.15c.43-.35.49-.98.15-1.41-.12-.15-2.879-3.627-.07-6.436 1.309-1.31 1.928-2.699 1.838-4.138h.01Z" />
  </Icon>
);
export default SvgSourceHeatIcon;
SvgSourceHeatIcon.displayName = 'SvgSourceHeatIcon';
