import { FC } from 'react';

import { Placeholder, usePlaceholderComponentsForRendering } from '@sitecore/common';
import { LineUpRendering } from '@sitecore/types/manual/LineUp';
import { Grid } from '@sparky';

import { LineUpMainPlaceholder } from './LineUpMainPlaceholder';
import ArticleCard from '../ArticleCard/ArticleCard';
import ProductCard from '../ProductCard/ProductCard';

const LineUp: FC<LineUpRendering> = ({ fields = {}, params = {}, placeholders = {} }) => {
  const { items = [] } = fields;
  const { hasFixedWidthOption, mobileVariant = 'default' } = params;
  const hasFixedWidth = !!Number(hasFixedWidthOption);

  const activePlaceholder = (Object.keys(placeholders)[0] as keyof LineUpRendering['placeholders']) ?? 'none';
  const placeholderItemsLength = Object.values(placeholders)[0]?.length ?? items.length ?? 1;
  const defaultLimit = {
    initial: '1',
    md: placeholderItemsLength >= 2 || hasFixedWidth ? '2' : `${placeholderItemsLength}`,
    xl: placeholderItemsLength >= 3 || hasFixedWidth ? '3' : `${placeholderItemsLength}`,
  };

  const responsivePlaceholderLimits = {
    none: defaultLimit,
    'jss-line-up-main': defaultLimit,
    'jss-usp-line-up-main': {
      initial: '1',
      md: '2',
      lg: '4',
    },
    'jss-content-card-sm-compact-line-up-main': defaultLimit,
    'jss-testimonials-card-line-up-main': {
      initial: '1',
      md: '2',
      lg: '3',
    },
  };

  const contentCards = usePlaceholderComponentsForRendering('jss-content-card-sm-compact-line-up-main');
  const uspItems = usePlaceholderComponentsForRendering('jss-usp-line-up-main');

  return (
    <Grid
      columns={responsivePlaceholderLimits[activePlaceholder]}
      justifyContent="stretch"
      gridTemplateRows="1fr"
      gap={{ initial: '10', md: '16' }}>
      {activePlaceholder === 'none'
        ? items.map(item =>
            item.componentName === 'ArticleCard' ? (
              <ArticleCard {...item} key={item.id} />
            ) : (
              <ProductCard {...item} key={item.id} />
            ),
          )
        : null}
      <LineUpMainPlaceholder placeholders={placeholders} />
      {uspItems.map((child: JSX.Element) => {
        if (mobileVariant === 'compact') {
          Object.assign(child?.props?.children?.props?.rendering?.params, { mobileVariant: 'compact' });
        }

        return child;
      })}
      {contentCards.map((child: JSX.Element) => {
        if (child?.props?.children?.props?.rendering?.componentName === 'ContentCard') {
          Object.assign(child?.props?.children?.props?.rendering?.params, { mobileVariant: 'compact' });
        }

        return child;
      })}
      <Placeholder name="jss-testimonials-card-line-up-main" />
    </Grid>
  );
};

export default LineUp;
