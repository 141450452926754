/* eslint-disable dxp-rules/no-custom-styling */
import React, { FC } from 'react';

import { useSelfServiceAccount } from "@dxp-auth-be";
import { useFormatter } from '@i18n';
import { Box, Text, TextLink } from '@sparky';
import { styled } from '@sparky/stitches';
import { CrmAccountWithRole } from 'types-be/next-auth';

import logout from '../utils/auth/logout';

interface ImpersonationFields {
  loggedInAsText: string;
  logoutText: string;
}

const fields: ImpersonationFields = {
  loggedInAsText: 'Logged in as: {alias} - {value}',
  logoutText: 'Log out',
};

const ImpersonationBannerBorder = styled('div', {
  borderColor: '#D21242',
  borderStyle: 'solid',
  borderWidth: '8px',
  position: 'fixed',
  width: '100%',
  height: '100%',
  zIndex: 3,
  pointerEvents: 'none',
});

const ImpersonationBannerBox = styled('div', {
  position: 'fixed',
  top: 0,
  width: '100%',
  textAlign: 'center',
  backgroundColor: '#D21242',
  pointerEvents: 'auto',
  '@lg': {
    width: 'auto',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
});

const ImpersonationBannerContent = styled('div', {
  display: 'flex',
  alignItems: 'baseline',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '24px',
});

const TopBarText = ({
  format,
  selectedAccount,
}: {
  format: (message: string, context: MessageContext) => string;
  selectedAccount: CrmAccountWithRole;
}) => (
  <>
    {format(fields.loggedInAsText, {
      alias: (selectedAccount?.alias as string) ?? '',
      value: selectedAccount?.crmAccountNumber,
    })}
  </>
);

const ImpersonationBanner: FC<React.PropsWithChildren> = ({ children }) => {
  const { selectedAccount, isImpersonating } = useSelfServiceAccount();
  const { format } = useFormatter();

  if (!isImpersonating()) {
    return children;
  }
  return (
    <>
      <ImpersonationBannerBorder>
        <ImpersonationBannerBox>
          <Box paddingX={6} paddingY={2}>
            <ImpersonationBannerContent>
              <Text color={'textInverted'} weight={'bold'} size={'BodyXS'}>
                <TopBarText format={format} selectedAccount={selectedAccount} />
              </Text>
              <Text size={'BodyXS'}>
                <TextLink color={'textInverted'} emphasis="low" onClick={() => logout('champion')}>
                  {fields.logoutText}
                </TextLink>
              </Text>
            </ImpersonationBannerContent>
          </Box>
        </ImpersonationBannerBox>
      </ImpersonationBannerBorder>
      {children}
    </>
  );
};

export default ImpersonationBanner;
