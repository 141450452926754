import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import LoginMenu from '@apps/header/shared/multilabel/src/LoginMenu';
import { ProfileMenuLink } from '@apps/header/shared/multilabel/src/ProfileMenuLink';
import usePlaceholderComponentContent from '@apps/header/shared/multilabel/src/usePlaceholderComponentContent';
import { getEnecoBeXapiSiteApiV1Profile } from '@dc-be/client';
import useAuthenticatedDCBE from '@dc-be/hooks/useAuthenticatedDCBE';
import unwrapData from '@dc-be/utils/unwrapData';
import { useSession } from '@dxp-auth';
import { useSelfServiceAccount } from "@dxp-auth-be";
import { MainMenuRightRendering } from '@sitecore/types/manual/NavigationBar';
import { NavLink } from '@sparky';
import { ProfileIcon, SpinnerIcon } from '@sparky/icons';


type LoginLinkProps = {
  isMinimalHeader: boolean;
  isMyZone: boolean;
  setIsProfileMenuOpen: Dispatch<SetStateAction<boolean>>;
};

const LoginLink: FC<LoginLinkProps> = ({ isMinimalHeader, isMyZone, setIsProfileMenuOpen }) => {
  const content = usePlaceholderComponentContent<MainMenuRightRendering>('jss-main-navigation-right', 'LoginMenu');
  const { selectedAccount, setEmptyAccount } = useSelfServiceAccount();
  const { data: session } = useSession() ?? {};
  const [displayName, setDisplayName] = useState(selectedAccount.alias);

  const {
    data,
    isLoading: isGetProfileLoading,
    error: isGetProfileError,
  } = useAuthenticatedDCBE(getEnecoBeXapiSiteApiV1Profile, {}, ['session'], session);

  const getProfileData = unwrapData(data);

  useEffect(() => {
    setDisplayName(selectedAccount.alias);
  }, [selectedAccount.alias]);

  useEffect(
    function switchToProspect() {
      if (!isGetProfileLoading && getProfileData && selectedAccount.alias === '') {
        const name = getProfileData?.displayName || '';
        setDisplayName(name);
        setEmptyAccount(name);
      }
    },
    [getProfileData, isGetProfileLoading, isGetProfileError, selectedAccount.alias, setEmptyAccount],
  );

  if (isMinimalHeader) {
    return null;
  }

  if (isGetProfileLoading) {
    return <SpinnerIcon />;
  }

  if (isMyZone) {
    return <ProfileMenuLink isLoading={false} displayName={displayName} onOpen={() => setIsProfileMenuOpen(true)} />;
  }

  if (session) {
    return (
      <NavLink href={content?.fields.selfServiceLink.value.href} leftIcon={<ProfileIcon />}>
        {content?.fields.selfServiceLink.value.text}
      </NavLink>
    );
  }

  return <LoginMenu />;
};

export default LoginLink;
