import type { FC } from 'react';

import useDC from '@dc/useDC';

import GTMTag from './GTMTag';
import { useEncodedCustomerId } from './useEncodedCustomerId';

const GTM: FC = () => {
  const { customerId, accountId } = useDC();
  const customerIdEncoded = useEncodedCustomerId(customerId);

  // If we have a customerId but no customerIdEncoded (yet), we don't want to render the GTM tag
  if (customerId && !customerIdEncoded) {
    return null;
  }

  return <GTMTag customerId={customerId?.toString()} customerIdEncoded={customerIdEncoded} accountId={accountId} />;
};

export default GTM;
