import { useEffect, useState } from 'react';

export const useEncodedCustomerId = (customerId: number | null | undefined) => {
  const [encoded, setEncoded] = useState<string>();

  useEffect(() => {
    const encodeId = async () => {
      if (customerId) {
        const hash = await sha256Hash(customerId.toString());
        setEncoded(hash);
      } else {
        setEncoded(undefined);
      }
    };

    encodeId();
  }, [customerId]);

  return encoded;
};

/**
 * Generates a SHA-256 hash of the input string and returns it as a hex string
 * @param message - The string to be hashed
 * @returns Promise<string> - The hex-encoded SHA-256 hash
 */
async function sha256Hash(message: string): Promise<string> {
  // Encode the message as UTF-8
  const msgBuffer = new TextEncoder().encode(message);

  // Generate the hash using Web Crypto API
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  // Convert the hash to hex string
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

  return hashHex;
}
