import React from 'react';

import { Locale as LocaleCode } from '@common/application';
import { Mode, PropsBase, PropsMulti, PropsRange, PropsSingle } from 'react-day-picker';
import { Locale, enGB, fr, nl } from 'react-day-picker/locale';

import { DayButton } from './components/DayButton';
import { MonthCaption } from './components/MonthCaption';
import { Weekday } from './components/Weekday';
import { DayPicker } from './Datepicker.styles';
import { CloseIcon } from '../../icons';
import { useI18nTranslations } from '../../providers/i18n';
import { useLocale } from '../../providers/locale';
import { Box } from '../Box/Box';
import { Button } from '../Button/Button';
import { Card } from '../Card/Card';
import { Divider } from '../Divider/Divider';
import { IconButton } from '../IconButton/IconButton';
import { Stack } from '../Stack/Stack';

export const dateLocale: Record<LocaleCode, Locale> = {
  'nl-NL': nl,
  'nl-BE': nl,
  'fr-BE': fr,
  'en-NL': enGB,
  'en-GB': enGB,
};

export const Datepicker: React.FC<Props> = ({
  onClose,
  onSave,
  onReset,
  mode = 'single',
  resetButtonLabel,
  saveButtonLabel,
  fromDate,
  fromYear,
  toDate,
  toYear,
  numberOfMonths = 1,
  disabled = [],
  disableWeekends,
  ...props
}) => {
  const { save: saveA11yLabel, reset: resetA11yLabel } = useI18nTranslations();
  const locale = useLocale();

  const disabledDates = Array.isArray(disabled) ? disabled : [disabled];

  let endMonth, startMonth;

  if (fromDate) {
    startMonth = fromDate;
    disabledDates.push({ before: fromDate });
  } else if (fromYear) {
    startMonth = new Date(fromYear, 0);
    disabledDates.push({ before: startMonth });
  }

  if (toDate) {
    endMonth = toDate;
    disabledDates.push({ after: toDate });
  } else if (toYear) {
    endMonth = new Date(toYear, 11, 31);
    disabledDates.push({ after: endMonth });
  }

  if (disableWeekends) {
    disabledDates.push({ dayOfWeek: [0, 6] });
  }

  return (
    <Card>
      {onClose ? (
        <>
          <Stack alignX="end">
            <IconButton label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider />
        </>
      ) : null}
      <DayPicker
        mode={mode}
        locale={dateLocale[locale]}
        disabled={disabledDates}
        fixedWeeks
        endMonth={endMonth}
        startMonth={startMonth}
        components={{
          DayButton,
          // eslint-disable-next-line react/jsx-no-useless-fragment
          NextMonthButton: () => <></>,
          MonthCaption,
          // eslint-disable-next-line react/jsx-no-useless-fragment
          PreviousMonthButton: () => <></>,
          Weekday,
        }}
        numberOfMonths={numberOfMonths}
        pagedNavigation={!!(numberOfMonths && numberOfMonths > 1)}
        {...props}
      />
      {onSave || onReset ? (
        <>
          <Divider />
          <Box padding="6">
            <Stack alignX="start" gap="5" direction="row">
              {onSave ? (
                <Button size="compact" onClick={onSave}>
                  {saveButtonLabel ?? saveA11yLabel}
                </Button>
              ) : null}
              {onReset ? (
                <Button size="compact" action="secondary" type="reset" onClick={onReset}>
                  {resetButtonLabel ?? resetA11yLabel}
                </Button>
              ) : null}
            </Stack>
          </Box>
        </>
      ) : null}
    </Card>
  );
};

export interface DayPickerProps<T extends Mode = Mode> extends PropsBase {
  mode?: T;
  selected?: T extends 'range'
    ? PropsRange['selected']
    : T extends 'multiple'
      ? PropsMulti['selected']
      : PropsSingle['selected'];
  onSelect?: T extends 'range'
    ? PropsRange['onSelect']
    : T extends 'multiple'
      ? PropsMulti['onSelect']
      : PropsSingle['onSelect'];
}
interface Props extends Omit<DayPickerProps, 'locale'> {
  disableWeekends?: boolean;
  fromDate?: Date;
  fromYear?: number;
  /**
   * Displays a close button, fired when button is clicked
   */
  onClose?(): void;
  onReset?(): void;
  onSave?(): void;
  resetButtonLabel?: string;
  saveButtonLabel?: string;
  toDate?: Date;
  toYear?: number;
}
