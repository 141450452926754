// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgHappyPowerIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2Zm-6 15.92V18c0-.55-.45-1-1-1s-1 .45-1 1v.92c-3.39-.49-6-3.39-6-6.92s2.61-6.43 6-6.92V6c0 .55.45 1 1 1s1-.45 1-1v-.92c3.39.49 6 3.39 6 6.92s-2.61 6.43-6 6.92ZM10 12c0 .83-.67 1.5-1.5 1.5S7 12.83 7 12s.67-1.5 1.5-1.5 1.5.67 1.5 1.5Zm7 0c0 .83-.67 1.5-1.5 1.5S14 12.83 14 12s.67-1.5 1.5-1.5 1.5.67 1.5 1.5Z" />
  </Icon>
);
export default SvgHappyPowerIcon;
SvgHappyPowerIcon.displayName = 'SvgHappyPowerIcon';
