// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgToonIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M19 4H5C3.34 4 2 5.34 2 7v10c0 1.66 1.34 3 3 3h14c1.66 0 3-1.34 3-3V7c0-1.66-1.34-3-3-3Zm0 13H5V7h14v10ZM9 15H7v-4c0-.55.45-1 1-1s1 .45 1 1v4Zm4 0h-2v-5c0-.55.45-1 1-1s1 .45 1 1v5Zm4 0h-2v-3c0-.55.45-1 1-1s1 .45 1 1v3Z" />
  </Icon>
);
export default SvgToonIcon;
SvgToonIcon.displayName = 'SvgToonIcon';
